import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { getClassNames } from '../../utils';

import './style.scss';

type AnswerCardProps = {
    isActive?: boolean;
    isCorrect?: boolean;
    disabled?: boolean;
};

export const AnswerCard: FC<
    PropsWithChildren<HTMLAttributes<HTMLDivElement> & AnswerCardProps>
> = ({ children, isActive, isCorrect, disabled, ...rest }): JSX.Element => (
    <div
        className={getClassNames(
            'AnswerCard',
            disabled && 'AnswerCard--disabled',
            isActive
                ? isCorrect
                    ? 'AnswerCard--correct'
                    : 'AnswerCard--not-correct'
                : ''
        )}
        {...rest}
    >
        <div className={getClassNames('AnswerCard__content')}>{children}</div>
    </div>
);
