import type { FC } from 'react';

import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import croissantCorrect from './assets/croissant-quiz/finish/correct.svg';
import croissantCorrectMobile from './assets/croissant-quiz/finish/m-correct.svg';
import croissantNotCorrectMobile from './assets/croissant-quiz/finish/m-not-correct.svg';
import croissantNotCorrect from './assets/croissant-quiz/finish/not-correct.svg';
import soapCorrect from './assets/soap-quiz/finish/correct.svg';
import soapCorrectMobile from './assets/soap-quiz/finish/m-correct.svg';
import soapNotCorrectMobile from './assets/soap-quiz/finish/m-not-correct.svg';
import soapNotCorrect from './assets/soap-quiz/finish/not-correct.svg';
import { Finish, Main, Pick, Quiz, Share } from './pages';
import { routes } from './routes';

import './styles/normalize.scss';

export const App: FC = () => {
    const [faviconType, setFaviconType] = useState<'-1' | ''>('-1');
    useEffect(() => {
        const images = [
            croissantCorrect,
            croissantCorrectMobile,
            croissantNotCorrect,
            croissantNotCorrectMobile,
            soapCorrectMobile,
            soapNotCorrect,
            soapCorrect,
            soapNotCorrectMobile
        ];
        for (const image of images) {
            const img = new Image();
            img.src = image;
        }
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = `https://makefor.samokat.ru/static_files/FVKN${faviconType}.png`;
        setTimeout(() => {
            if (faviconType === '') {
                setFaviconType('-1');
            } else {
                setFaviconType('');
            }
        }, 2000);
    }, [faviconType]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path={routes.main} element={<Main />} />
                <Route path={routes.pick} element={<Pick />} />
                <Route path={routes.quiz} element={<Quiz />} />
                <Route path={routes.finish} element={<Finish />} />
                <Route path="/share/:type" element={<Share />} />
                <Route path="*" element={<Navigate to={routes.main} />} />
            </Routes>
        </BrowserRouter>
    );
};
