import type { FC } from 'react';

import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';

import { routes } from '../../routes';

export const Share: FC = (): JSX.Element => {
    const { type } = useParams();
    return (
        <div className="Share">
            <Helmet>
                <meta
                    name="twitter:image"
                    content={`https://makefor.samokat.ru/static_files/${type}.png`}
                />
                <meta
                    property="og:image"
                    content={`https://makefor.samokat.ru/static_files/${type}.png`}
                />
            </Helmet>
            <Navigate to={routes.main} />
        </div>
    );
};
