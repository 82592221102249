import type { FC } from 'react';

import { useAtomValue, useSetAtomState } from '@mntm/precoil';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import correct from '../../assets/correct.svg';
import notCorrect from '../../assets/not-correct.svg';
import samokatLogoCircle from '../../assets/samokat-circle.svg';
import samokatLogo from '../../assets/samokat.svg';
import transition from '../../assets/transition.svg';
import { AnswerCard, Page, QuizCard } from '../../components';
import { routes } from '../../routes';
import { countCorrectAnswersAtom, pickedValueAtom } from '../../storage';
import { QuizList } from '../../types';
import { getClassNames, importAssets, importIcons } from '../../utils';
import quizList from '../../utils/quiz-list.json';

import './style.scss';

export const Quiz: FC = (): JSX.Element => {
    const pickedValue = useAtomValue(pickedValueAtom);
    const setCountCorrectAnswersAtom = useSetAtomState(countCorrectAnswersAtom);

    const [correctAnswers, setCorrectAnswers] = useState<number[]>([]);
    const [activeCard, setActiveCard] = useState<number>(0);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [stage, setStage] = useState<number>(0);
    const quiz = (quizList as QuizList)[pickedValue || 'soap'];
    const [height, setHeight] = useState<number>(0);

    const navigate = useNavigate();

    const images = importAssets(pickedValue || 'soap');
    const icons = {
        next: importIcons(pickedValue || 'soap', 'next'),
        current: importIcons(pickedValue || 'soap', 'current')
    };
    const isMobile = window.matchMedia('(orientation: portrait)').matches;
    useEffect(() => {
        if (!pickedValue.length || pickedValue === '') {
            return navigate(routes.main);
        }
        const answers = document.getElementsByClassName('Page__answers');
        const answersElement = answers[0];
        setHeight(answersElement.clientHeight);
    }, []);

    const onClickHandler = (index: number): void => {
        if (activeCard >= 1 || isActive) return;
        setActiveCard(index + 1);
        setIsActive(true);
        setTimeout(
            () => {
                let count = correctAnswers.filter((item) => item !== 0).length;
                const isCorrect = Number(
                    quiz[stage].answers[index].correct || false
                );
                if (isCorrect) {
                    count++;
                }
                setCorrectAnswers((prevState) => [...prevState, isCorrect]);
                setActiveCard(0);
                setTimeout(() => {
                    setIsActive(false);
                }, 550);
                if (stage + 1 >= quiz.length) {
                    setTimeout(() => {
                        setCountCorrectAnswersAtom(count);
                        navigate(routes.finish);
                    }, 100);
                } else setStage((prevState: number) => prevState + 1);
            },
            isMobile ? 1500 : 2250
        );
    };

    const getLogoHeight = (): number => {
        if (
            !document.getElementsByClassName('Page__answers')[0]
                ?.clientHeight ||
            !document.getElementsByClassName('Page__quizCard')[0]
                ?.clientHeight ||
            !document.getElementsByClassName('Page__samokat')[0]?.clientHeight
        )
            return window.innerHeight / 3;
        return (
            (window.innerHeight -
                (document.getElementsByClassName('Page__answers')[0]
                    .clientHeight +
                    document.getElementsByClassName('Page__quizCard')[0]
                        .clientHeight +
                    document.getElementsByClassName('Page__samokat')[0]
                        .clientHeight)) *
            0.75
        );
    };
    const [heightLogo, setHeightLogo] = useState<number>(getLogoHeight());
    useEffect(() => {
        setHeightLogo(getLogoHeight());
    }, []);
    // useEffect(() => {
    //     window.scrollTo({
    //         behavior: 'smooth',
    //         top: 10000
    //     });
    // }, [stage]);

    return (
        <Page
            name={getClassNames(
                `Quiz`,
                isActive ? 'Quiz--fadeout' : stage ? 'Quiz--fadein' : ''
            )}
            // style={{ marginTop: (isMobile && -(height * 1.5)) || 0 }}
        >
            <div className="Transition">
                <img src={transition} alt="" />
            </div>
            <img
                className="Page__samokat"
                src={samokatLogo}
                alt="Логотип самокат"
            />
            <img
                className="Page__samokat--circle"
                src={samokatLogoCircle}
                alt="Логотип самокат"
            />
            <div className="Page__quizCard">
                <QuizCard
                    stage={`${stage + 1}/${quiz.length}`}
                    header={
                        isMobile
                            ? quiz[stage].header_mobile || quiz[stage].header
                            : quiz[stage].header
                    }
                >
                    <div className="Quiz__icons">
                        {Array.from(Array(quiz.length).keys()).map((item) => {
                            if (item < stage) {
                                const isCorrectAnswered = correctAnswers[item];
                                return (
                                    <img
                                        key={item}
                                        src={
                                            isCorrectAnswered
                                                ? correct
                                                : notCorrect
                                        }
                                        alt={correctAnswers[item].toString()}
                                    />
                                );
                            } else if (item === stage) {
                                return (
                                    <img
                                        key={item}
                                        src={icons.current[item]}
                                        alt=""
                                    />
                                );
                            } else
                                return (
                                    <img
                                        key={item}
                                        src={icons.next[item]}
                                        alt=""
                                    />
                                );
                        })}
                    </div>
                </QuizCard>
            </div>
            <div
                className="Page__logo"
                style={{ height: (isMobile && heightLogo) || 'unset' }}
            >
                <img src={images[stage]} alt="" />
            </div>
            <div className="Page__answers">
                {quiz[stage].answers.map((item, index) => (
                    <AnswerCard
                        disabled={
                            (activeCard >= 1 && activeCard !== index + 1) ||
                            isActive
                        }
                        isCorrect={item.correct}
                        isActive={activeCard === index + 1}
                        key={item.text}
                        onClick={() => onClickHandler(index)}
                    >
                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                    </AnswerCard>
                ))}
            </div>
        </Page>
    );
};
