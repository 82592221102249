import { ButtonHTMLAttributes, FC } from 'react';

import './style.scss';
import { getClassNames } from '../../utils';

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (
    props
): JSX.Element => {
    const { className, ...rest } = props;
    return <button className={getClassNames('Button', className)} {...rest} />;
};
