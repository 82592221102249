import type { FC } from 'react';

import { useSetAtomState } from '@mntm/precoil';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import croissantLogo from '../../assets/croissant.svg';
import soapLogo from '../../assets/soap.svg';
import { Button, Card, Page, Picked } from '../../components';
import { routes } from '../../routes';
import { pickedValueAtom } from '../../storage';
import { PickedCopyright, PickedVariables } from '../../types';
import pickCopyright from '../../utils/pick-copyright.json';

import './style.scss';

export const Pick: FC = (): JSX.Element => {
    const [picked, setPicked] = useState<PickedVariables | ''>('');
    const setPickedValue = useSetAtomState(pickedValueAtom);

    const navigate = useNavigate();

    const pick = (value: PickedVariables | '') => {
        return (): void => {
            if (value === picked) navigate(routes.quiz);
            else setPicked(value);
        };
    };

    useEffect(() => {
        setPickedValue(picked);
    }, [picked]);

    const push = (to: routes) => {
        return (): void => {
            if (!picked.length) return;
            (
                window as Window &
                    typeof globalThis & {
                        dataLayer: unknown[];
                    }
            ).dataLayer.push({
                event: 'gtm-ee-event',
                'gtm-ee-event-category': picked
            });
            navigate(to);
        };
    };

    return (
        <Page name="Pick">
            <Card
                cornerPosition={
                    picked === ''
                        ? undefined
                        : picked === 'soap'
                        ? 'right'
                        : 'left'
                }
            >
                <p
                    className="Pick__text"
                    dangerouslySetInnerHTML={{
                        __html:
                            picked !== ''
                                ? (pickCopyright as PickedCopyright)[picked]
                                : 'Ваша цель — пройти все проверки и&nbsp;попасть на витрину. Какой продукт будете продвигать?'
                    }}
                />
            </Card>
            <div className="Pick__promo">
                <p>
                    В конце —{' '}
                    <strong>получите скидку&nbsp;15% на&nbsp;заказ</strong>
                </p>
            </div>
            <div className="Page__choice">
                <Picked
                    onClick={pick('croissant')}
                    name="croissant"
                    picked={picked}
                >
                    <img src={croissantLogo} />
                </Picked>
                <Picked onClick={pick('soap')} name="soap" picked={picked}>
                    <img src={soapLogo} />
                </Picked>
            </div>
            <div className="Page__button">
                <Button disabled={!picked.length} onClick={push(routes.quiz)}>
                    Выбрать
                </Button>
            </div>
        </Page>
    );
};
