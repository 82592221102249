import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { getClassNames } from '../../utils';

import './style.scss';

type CardProps = {
    cornerPosition?: 'left' | 'right';
};

export const Card: FC<
    PropsWithChildren<HTMLAttributes<HTMLDivElement> & CardProps>
> = (props): JSX.Element => {
    const { children, className, cornerPosition, ...rest } = props;
    return (
        <div className={getClassNames('Card', className)} {...rest}>
            <div className="Card__content">{children}</div>
            <div
                className={getClassNames(
                    'Card__corner',
                    'Card__corner--' + (cornerPosition || 'none')
                )}
            />
        </div>
    );
};
