import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { getClassNames } from '../../utils';

import './style.scss';

export type PageProps = {
    name: string;
    style?: CSSProperties;
};

export const Page: FC<PropsWithChildren<PageProps>> = ({
    children,
    name,
    style
}): JSX.Element => (
    <div className={getClassNames('Page', 'Page--' + name)}>
        <div className="Page__content" style={style}>
            {children}
        </div>
    </div>
);
