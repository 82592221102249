import type { FC, PropsWithChildren } from 'react';

import './style.scss';

type QuizCardProps = {
    stage: string;
    header: string;
};

export const QuizCard: FC<PropsWithChildren<QuizCardProps>> = ({
    children,
    stage,
    header
}): JSX.Element => (
    <div className="QuizCard">
        <div className="QuizCard__content">
            <div className="QuizCard__stage">{stage}</div>
            <div
                className="QuizCard__header"
                dangerouslySetInnerHTML={{ __html: header }}
            ></div>
            {children}
        </div>
    </div>
);
