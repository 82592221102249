import { PickedVariables } from '../types';

export const importAssets = (type: PickedVariables) => {
    switch (type) {
        case 'soap': {
            const r = require.context('../assets/soap-quiz');
            return r.keys().map((item) => r(item));
        }
        case 'croissant': {
            const r = require.context('../assets/croissant-quiz');
            return r.keys().map((item) => r(item));
        }
    }
};

export const importIcons = (
    type: PickedVariables,
    stage: 'next' | 'current'
) => {
    if (stage === 'next') {
        switch (type) {
            case 'soap': {
                const r = require.context('../assets/soap-quiz/icons/next');
                return r.keys().map((item) => r(item));
            }
            case 'croissant': {
                const r = require.context(
                    '../assets/croissant-quiz/icons/next'
                );
                return r.keys().map((item) => r(item));
            }
        }
    } else {
        switch (type) {
            case 'soap': {
                const r = require.context('../assets/soap-quiz/icons/current');
                return r.keys().map((item) => r(item));
            }
            case 'croissant': {
                const r = require.context(
                    '../assets/croissant-quiz/icons/current'
                );
                return r.keys().map((item) => r(item));
            }
        }
    }
};
