import type { FC } from 'react';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/mainLogo.svg';
import samokatLogo from '../../assets/samokat.svg';
import { Button, Page } from '../../components';
import { routes } from '../../routes';
import { importAssets } from '../../utils';

import { MainGallery } from './Gallery';

import './style.scss';

export const Main: FC = (): JSX.Element => {
    const navigate = useNavigate();

    const push = (to: routes) => {
        return (): void => {
            navigate(to);
        };
    };

    useEffect(() => {
        const images = [...importAssets('soap'), ...importAssets('croissant')];
        for (const image of images) {
            const img = new Image();
            img.src = image;
        }
    }, []);

    return (
        <Page name="Main">
            <MainGallery />
            <img
                className="Page__samokat"
                src={samokatLogo}
                alt="Логотип самокат"
                onClick={() => window.open('https://samokat.ru', '_blank')}
            />
            <img
                className="Page__logo"
                src={logo}
                alt="Главная картинка страницы!"
            />
            <div className="Page__title">По полочкам</div>
            <div className="Page__subtitle">
                Вкладываете душу в производство или домашнюю&nbsp;готовку?
                <br className="No--mobile" /> Узнайте, сможет ли ваш продукт
                попасть на&nbsp;полки&nbsp;Самоката!
            </div>
            <div className="Page__button">
                <Button onClick={push(routes.pick)}>Пройти тест</Button>
            </div>
            <MainGallery />
        </Page>
    );
};
