import type { FC, PropsWithChildren } from 'react';

import { PickedVariables } from '../../types';
import { getClassNames } from '../../utils';

import './style.scss';

type PickProps = {
    onClick: () => void;
    name: PickedVariables;
    picked: string;
};

export const Picked: FC<PropsWithChildren<PickProps>> = ({
    children,
    onClick,
    picked,
    name
}): JSX.Element => {
    return (
        <div
            onClick={onClick}
            className={getClassNames(
                'Picked',
                picked === name && 'Picked--active Picked--active--' + name,
                picked.length && picked !== name && 'Picked--inactive'
            )}
        >
            {children}
        </div>
    );
};
