export const share = (
    target: 'vk' | 'tg' | 'ok',
    name: string,
    url: string
): void => {
    switch (target) {
        case 'vk': {
            window.open(
                `https://vk.com/share.php?&title=По полочкам&url=${window.location.origin}/share/${name}&description=Пройди тест и узнай достоин ли твой продукт оказаться на полке!&image=${url}`,
                '_blank'
            );
            break;
        }
        case 'tg': {
            window.open(
                `https://t.me/share/url?url=${window.location.origin}/share?type=${name}&text=Пройди короткий тест и получи скидку`,
                '_blank'
            );
            break;
        }
        case 'ok': {
            window.open(
                `https://connect.ok.ru/offer?url=${window.location.origin}/share/${name}&title=По полочкам!&comment=Пройди тест и узнай достоин ли твой продукт оказаться на полке!&imageUrl=${url}`,
                '_blank'
            );
        }
    }
};
