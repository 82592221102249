import type { FC } from 'react';

export const MainGallery: FC = (): JSX.Element => (
    <div className="Page__gallery">
        <span>
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
            <strong>В конце теста подарок — промокод на скидку 15%!</strong>{' '}
        </span>
    </div>
);
